import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { redirectTo, selectCurrentUser, selectIconPack } from "../../../../../store/ducks/auth.duck";
import { canEditCustomWhereabouts } from "../../../../../utils/AccessControl";
import { PageTitle } from "../../../../UI/atoms/PageTitle";
import TextField from "../../../../UI/atoms/TextField";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import {
  IconFilter,
  WhereaboutsImagePicker
} from "../../../../dialogs/custom-whereabouts-dialog/WhereaboutsImagePicker";
import {
  createUpdateWhereaboutsOption,
  CustomWhereaboutsType,
  FreeBusyStatus,
  WhereaboutsOption
} from "../../../../../services/WhereaboutOptions";
import { IconFilterRow } from "./components/IconFilterRow";
import {
  WhereaboutsAvailabilityToggle
} from "../../../../dialogs/custom-whereabouts-dialog/WhereaboutsAvailabilityToggle";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { CreatingOfficeContentCard } from "./components/CreatingOfficeContentCard";
import { useParams } from "react-router";
import {
  loadWhereaboutsOptions,
  selectAllWhereaboutsOptions
} from "../../../../../store/ducks/whereaboutsOptions.duck";
import { failureNotification } from "../../../../../store/ducks/notification.duck";
import { safeParseInt } from "../../../../../utils/MathUtils";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { selectHasGlobalConfigLoaded } from "../../../../../store/ducks/config.duck";
import { HeadlineMedium } from "../../../../UI/atoms/fonts/Headline";
import { AdditionalLeaveOptions } from "./components/AdditionalLeaveOptions";

function getCustomWhereaboutsType(isLeaveType: boolean): CustomWhereaboutsType {
  if (isLeaveType) {
    return CustomWhereaboutsType.LEAVE;
  } else {
    return CustomWhereaboutsType.CUSTOM;
  }
}

export function AddCustomWhereaboutsPage(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const canEdit = useMemo(() => canEditCustomWhereabouts(currentUser), [currentUser]);
  const iconPack = useSelector(selectIconPack);
  const globalConfigLoaded = useSelector(selectHasGlobalConfigLoaded);
  const allCustomWhereabouts = useSelector(selectAllWhereaboutsOptions);
  const params: any = useParams();
  const [whereaboutsName, setWhereaboutsName] = useState('');
  const [colour, setColour] = useState<undefined | string>(undefined);
  const [image, setImage] = useState<undefined | string>(undefined);
  const [enabled, setEnabled] = useState(true);
  const [iconFilter, setIconFilter] = useState<IconFilter | undefined>(undefined);
  const [freeBusyStatus, setFreeBusyStatus] = useState(FreeBusyStatus.FREE);
  const [loading, setLoading] = useState(false);
  const [isLeaveOption, setLeaveOption] = useState(false);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const customWhereaboutsId = !!params.customWhereaboutsId ? safeParseInt(params.customWhereaboutsId) : undefined;
  const key = useMemo(() => whereaboutsName.toUpperCase().replace(/[^a-z0-9+]+/gi, '_'), [whereaboutsName]);

  useEffect(() => {
    if (freeBusyStatus === FreeBusyStatus.OOF || freeBusyStatus === FreeBusyStatus.BUSY) setColour('grey');
    else if (freeBusyStatus === FreeBusyStatus.WORKING_ELSEWHERE || freeBusyStatus === FreeBusyStatus.TENTATIVE) setColour('yellow');
    else setColour('green');
  }, [freeBusyStatus]);

  const editingCustomWhereabouts = useMemo(() => {
    if (customWhereaboutsId && globalConfigLoaded) {
      const editingOption = allCustomWhereabouts.find((cw => cw.id === customWhereaboutsId));
      if (editingOption) {
        return editingOption;
      } else {
        dispatch(failureNotification('Could not find custom whereabouts'))
        dispatch(redirectTo('/manage/custom-whereabouts'));
      }
    }
    return undefined;
  }, [customWhereaboutsId, globalConfigLoaded, allCustomWhereabouts, dispatch]);

  useEffect(() => {
    if (editingCustomWhereabouts) {
      setWhereaboutsName(editingCustomWhereabouts.label);
      setColour(editingCustomWhereabouts.colour);
      setImage(editingCustomWhereabouts.image);
      setEnabled(editingCustomWhereabouts.enabled);
      setFreeBusyStatus(editingCustomWhereabouts.freeBusyStatus);
      setIsPrivate(editingCustomWhereabouts.isPrivate);
      setRequiresApproval(editingCustomWhereabouts.requiresApproval);
      setLeaveOption(editingCustomWhereabouts.whereaboutsType === CustomWhereaboutsType.LEAVE)
    }
  }, [editingCustomWhereabouts]);

  const onSaveClick = async () => {

    setLoading(true);
    try {
      const whereaboutsOption: WhereaboutsOption = {
        id: editingCustomWhereabouts?.id ?? 0,
        dataTest: key.toLowerCase(),
        alt: whereaboutsName,
        image: image ?? '',
        key: key,
        companyId: currentUser?.companyEntity.id ?? '',
        label: whereaboutsName,
        enabled: enabled,
        colour: colour ?? '',
        freeBusyStatus: freeBusyStatus,
        // Values to setup
        status: key,
        outlookNote: whereaboutsName,
        freeTextEnabled: false,
        // Values defaulted on server
        slackNote: '',
        pickable: editingCustomWhereabouts?.pickable ?? false,
        required: editingCustomWhereabouts?.required ?? false,

        requiresApproval: requiresApproval,
        whereaboutsType: getCustomWhereaboutsType(isLeaveOption),
        isPrivate: isPrivate
      }
      await createUpdateWhereaboutsOption(whereaboutsOption);
      await dispatch(loadWhereaboutsOptions(true));
      dispatch(redirectTo('/manage/custom-whereabouts'));
    } catch (e: any) {
      console.error(e);
      dispatch(failureNotification('Failed to create custom option'));
    } finally {
      setLoading(false)
    }
  }

  if (!canEdit) {
    return null;
  }

  return <>
    {(loading || (!globalConfigLoaded && customWhereaboutsId)) && <LoadingSpinner fullScreen={true} hideText={true} />}
    <PageTitle text={'Create Custom Whereabouts'} headlineLarge={true} style={{margin: '0 0 32px 0'}} />

    <BodyRegular style={{marginBottom: 8}} weight={600}>1. Whereabouts name</BodyRegular>
    <TextField onChange={setWhereaboutsName} fullWidth={true} label={''} value={whereaboutsName} disabled={loading} />

    <BodyRegular style={{marginBottom: 8, marginTop: 48}} weight={600}>2. Choose an icon</BodyRegular>

    <IconFilterRow onSelect={setIconFilter} selected={iconFilter} disabled={loading} />

    <WhereaboutsImagePicker color={colour}
                            iconPack={iconPack}
                            freeBusyStatus={freeBusyStatus}
                            onSelect={(icon: string) => setImage(icon)}
                            selectedImage={image}
                            iconFilter={iconFilter}
                            disabled={loading} />

    <BodyRegular style={{marginBottom: 8, marginTop: 48}} weight={600}>3. Show availability on Outlook or Google calendar as:</BodyRegular>

    <WhereaboutsAvailabilityToggle onSelect={(val: FreeBusyStatus) => setFreeBusyStatus(val)}
                                   disabled={loading}
                                   selected={freeBusyStatus} />

    <HeadlineMedium style={{marginTop: 48, marginBottom: 24}}>Final set up</HeadlineMedium>

    <AdditionalLeaveOptions isLeaveOption={isLeaveOption} onIsLeaveOptionChange={setLeaveOption}
                            requiresApproval={requiresApproval} onRequiresApprovalChange={setRequiresApproval}
                            isPrivate={isPrivate} onIsPrivateChange={setIsPrivate} />

    <CreatingOfficeContentCard style={{marginTop: 32}} />

    <PrimaryButton click={onSaveClick}
                   text={"button.save"}
                   fullWidth={true}
                   size={'large'}
                   dataTest={'save-custom-whereabouts-button'}
                   style={{marginBottom: 16, marginTop: 48}}
                   disabled={loading} />
  </>;
}

interface Props {
}
