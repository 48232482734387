import React from 'react';
import { Pill } from "../../../../UI/atoms/Pill";
import Colours from "../../../../UI/atoms/Colours";
import { BodyVerySmall } from "../../../../UI/atoms/fonts/Body";
import { GroupedApprovalRequest } from "../../../../../models/approval-requests.models";
import { useSelector } from "react-redux";
import { selectWhereaboutsOptionById } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";

export function ApprovalCustomWhereaboutsPill(props: Props) {
  const approval = props.approval;
  const optionAm: WhereaboutsOption | undefined = useSelector(state => selectWhereaboutsOptionById(state, approval?.amWhereaboutsId ?? 0))
  const optionPm: WhereaboutsOption | undefined = useSelector(state => selectWhereaboutsOptionById(state, approval?.pmWhereaboutsId ?? 0))
  if (!optionAm && !optionPm) {
    return null;
  }
  return (
    <Pill style={{marginLeft: 16}} backgroundColour={Colours.blue02}>
      {approval.amWhereaboutsId === approval.pmWhereaboutsId ? <>
        <BodyVerySmall weight={600}>{optionAm?.label}</BodyVerySmall>
      </> : <>
        {optionAm && <BodyVerySmall weight={600}>AM: {optionAm?.label}</BodyVerySmall>}
        {optionPm && <BodyVerySmall weight={600}>PM: {optionPm?.label}</BodyVerySmall>}
      </>}
    </Pill>
  )
}

interface Props {
  approval: GroupedApprovalRequest;
}
